import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthResponse } from '../components/models/auth';
import { Color } from '../components/models/color';
import { Currency } from '../components/models/currency';
import { OrderResponse } from '../components/models/order';

@Injectable({
  providedIn: 'root'
})
export class ApisAuthService {
  
  // http://api.smart-kids.apishipping.iraq-soft.info/
  BaseUrl ='http://api.smart-kids.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  public login(body):  Observable<AuthResponse>{
    // this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    // .set("Authorization","Bearer " + this.token);
    return this.http.post<AuthResponse>(this.BaseUrl+ 'admin/admin_general/login' ,body,{headers});
  }

  public getColor():  Observable<Color>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<Color>(this.BaseUrl+ 'admin/settings/get' ,{headers});
  }


  public addColor(body :FormData):  Observable<Color>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<Color>(this.BaseUrl+ 'admin/settings/edit' ,body,{headers});
  }

  public sendNotification(body):  Observable<OrderResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<OrderResponse>(this.BaseUrl+ 'admin/order/send-notification' ,body,{headers});
  }
  public getCurrency():  Observable<Currency>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<Currency>(this.BaseUrl+ 'admin/admin_general/currency/get' ,{headers});
  }

  public addCurrency(body :FormData):  Observable<Color>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<Color>(this.BaseUrl+ 'admin/admin_general/currency/add' ,body,{headers});
  }


  public deleteData(body :FormData):  Observable<Color>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<Color>(this.BaseUrl+ 'admin/settings/empty-data' ,body,{headers});
  }

}
