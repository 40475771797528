import { Component, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisAuthService } from 'src/app/services/apis-auth.service';

@Component({
  selector: 'app-add-currency',
  templateUrl: './add-currency.component.html',
  styleUrls: ['./add-currency.component.scss']
})
export class AddCurrencyComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  loadingCurrency =false;
  errorMessageCurrency : string;
  @Output() childAdded: EventEmitter<string> =   new EventEmitter();
  constructor(private http : ApisAuthService) { }

  ngOnInit(): void {
  }

  addCurrency(body :NgForm){
    this.loadingCurrency = true
    this.errorMessageCurrency ="";
    this.http.addCurrency(body.value).subscribe(response=>{ 
      this.loadingCurrency = false
          if(response.status == 1 ){
            body.resetForm();
            this.closeButton.nativeElement.click();
              this.childAdded.emit('currency Added Successfully');
              this.removeFaild();

          }
          else{
            this.errorMessageCurrency ='* '+ response.message;
          }
    })
  }
  removeFaild(){

  }
}
