
    <!--  Modal content for the above example -->
    <button type="button" class="btn btn-info waves-effect waves-light" data-toggle="modal" data-target="#bs-add-currency">Add Currency</button>
    <div class="modal" id="bs-add-currency" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                    <h4 class="modal-title" id="myLargeModalLabel">Add Currency</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="card-box">
                            <form  #data="ngForm" (ngSubmit)='addCurrency(data)'>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="userName">Currency Logo<span class="text-danger">*</span></label>
                                            <input type="text"  required  
                                                    class="form-control"  name="name" ngModel>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="userName">Cost According to Dinar<span class="text-danger">*</span></label>
                                            <input type="text"  required  
                                                    class="form-control"  name="cost" ngModel>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="alert" role="alert" #errorDiv>
                                             {{errorMessageCurrency}}
                                          </div>
                                    </div>
                                </div>
                                
                                <div class="form-group text-right m-b-0">
                                    <button class="btn btn-custom waves-effect waves-light AddUser" type="submit" [disabled]="loadingCurrency">
                                        Add
                                    </button>
                                    <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                        Cancel
                                    </button>
                                </div>
    
                            </form>
                        </div> <!-- end card-box -->
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->